import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  class: "mt-1 w-1/4 text-amff-black-2024",
  style: {"max-width":"8.5rem"}
}
const _hoisted_3 = { class: "mt-4 text-xl lg:text-3xl font-bold" }
const _hoisted_4 = { class: "hidden lg:block" }
const _hoisted_5 = { class: "relative fill-post-image w-72 bg-amff-dark-gray" }
const _hoisted_6 = {
  class: "ml-6 w-3/4 text-amff-black-2024",
  style: {"max-width":"38.5rem"}
}
const _hoisted_7 = { class: "text-2xl lg:text-3xl font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "text-xl font-light mb-2"
}
const _hoisted_9 = { class: "mt-4 font-display lg:text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")
  const _component_EventImage = _resolveComponent("EventImage")
  const _component_AnchorLink = _resolveComponent("AnchorLink")

  return (_openBlock(), _createBlock(_component_AnchorLink, {
    to: {
      name: 'event',
      params: { type: _ctx.event.type, slug: _ctx.event.slug },
    },
    class: "block px-container py-6 border-b border-amff-light-gray"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Label, { color: "amff-black" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.eventTypeLabel)), 1)
            ]),
            _: 1
          }),
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.event.hour) + "h", 1),
          _createVNode("div", null, _toDisplayString(_ctx.event.getDurationFormatted(_ctx.currentLang)), 1)
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            (_ctx.event.imageCard)
              ? (_openBlock(), _createBlock(_component_EventImage, {
                  key: 0,
                  path: _ctx.event.imageCard,
                  class: "absolute inset-0 object-cover w-full h-full"
                }, null, 8, ["path"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_Label, {
            outline: "",
            class: "truncate mb-4",
            color: "amff-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.event.subtitle), 1)
            ]),
            _: 1
          }),
          _createVNode("div", _hoisted_7, _toDisplayString(_ctx.event.title), 1),
          (_ctx.event.media)
            ? (_openBlock(), _createBlock("div", _hoisted_8, _toDisplayString(_ctx.event.media.directorNames.join(", ")), 1))
            : _createCommentVNode("", true),
          _createVNode("div", {
            class: "mt-3 font-thin lg:text-lg max-h-80 overflow-hidden",
            innerHTML: _ctx.event.description?.split('</p>')[0] + '</p>'
          }, null, 8, ["innerHTML"]),
          _createVNode("div", _hoisted_9, [
            (
              _ctx.event.accessType === 'full' || _ctx.event.accessType === 'early_full'
            )
              ? (_openBlock(), _createBlock(_component_Label, {
                  key: 0,
                  color: "amff-black",
                  outline: "",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.event.getAccessTypeFormatted(_ctx.currentLang)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.event.accessType === 'limited' || _ctx.event.accessType === 'free')
              ? (_openBlock(), _createBlock(_component_Label, {
                  key: 1,
                  color: "amff-black",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("events.book_tickets")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.event.accessType === 'purchase')
              ? (_openBlock(), _createBlock(_component_Label, {
                  key: 2,
                  color: "amff-black",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("events.buy_tickets")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.event.location?.name), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}