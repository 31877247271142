
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useEvents, EventsFilters } from "@/composables/useEvents";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { useRouter } from "vue-router";
import {
  formatDayWithWeekday,
  isoToTimestamp,
} from "@/utils/dateTimeFormatter";
import { EventModel } from "@/domain/models/EventModel";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Button from "@/components/Button.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import EventItem from "@/components/EventItem.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
// import BarricadeTape from "@/components/BarricadeTape.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import EmptyEventsBlock from "@/components/EmptyEventsBlock.vue";

export default defineComponent({
  components: {
    LoadingIndicator,
    Button,
    HorizontalScroller,
    EventItem,
    ArrowIcon,
    // BarricadeTape,
    EmptyEventsBlock,
  },
  setup() {
    const baseURL = process.env.BASE_URL;
    const router = useRouter();
    const { route, currentLang } = useRouteWithLang();
    const { events, isFetchingEvents, eventsError, filterEvents, fetchEvents } =
      useEvents();

    if (!events.value.length) {
      fetchEvents();
    }

    const filters = computed<EventsFilters>({
      get() {
        return {
          eventType: undefined,
          day: getDefaultDay(),
          locationId: undefined,
          ...route.query,
        };
      },
      set(newValue: EventsFilters) {
        router.push({
          query: {
            ...route.query,
            ...newValue,
          },
        });
      },
    });

    const eventsFiltered = ref<EventModel[]>([]);
    const availableDays = computed(() => [
      ...new Set(events.value.map((event) => event.day)),
    ]);
    const availableDaysByEventType = computed(() => [
      ...new Set(
        filterEvents({ eventType: filters.value.eventType }).map(
          (event) => event.day
        )
      ),
    ]);
    const availableEventTypesByDay = computed(() => [
      ...new Set(
        filterEvents({ day: filters.value.day }).map((event) => event.type)
      ),
    ]);
    const nextAvailableDay = computed(() => {
      const dayIndex = availableDays.value.findIndex(
        (day) => day === filters.value.day
      );

      if (availableDaysByEventType.value[dayIndex + 1]) {
        return availableDaysByEventType.value[dayIndex + 1];
      }
      return null;
    });

    watchEffect(() => {
      if (events.value.length) {
        eventsFiltered.value = filterEvents(filters.value);
      }
    });

    function getDefaultDay() {
      // https://stackoverflow.com/a/58633651/4217158
      const todayYYMMDD = new Date()
        .toLocaleString("sv", {
          timeZone: "Europe/Madrid",
        })
        .slice(0, 10);
      return (
        // today or nearest future date
        availableDays.value.find((day) => day >= todayYYMMDD) ||
        // if all dates are in the past, just select the first option
        availableDays.value[0]
      );
    }

    const root = ref<null | HTMLElement>(null);
    function scrollToTop() {
      root.value?.scrollIntoView();
    }

    function formatDay(isoDay: string) {
      return formatDayWithWeekday(isoToTimestamp(isoDay), currentLang.value);
    }

    return {
      eventsFiltered,
      eventsError,
      isFetchingEvents,
      filters,
      availableDays,
      availableEventTypesByDay,
      availableDaysByEventType,
      nextAvailableDay,
      scrollToTop,
      root,
      formatDay,
      AnchorLink,
      baseURL,
      currentLang,
    };
  },
});
