<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M23.5 12L13.393 4.5v6.207H1.5v2.586h11.893V19.5z"
      fill-rule="evenodd"
    />
  </svg>
</template>
