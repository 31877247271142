
import { computed, defineComponent } from "vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { EventModel } from "@/domain/models/EventModel";
import Label from "@/components/Label.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import EventImage from "@/components/EventImage.vue";

export default defineComponent({
  components: {
    Label,
    AnchorLink,
    EventImage,
  },
  props: {
    event: {
      type: EventModel,
      required: true,
    },
  },
  setup(props) {
    const eventTypeLabel = computed(() => {
      if (props.event.type === "concert") {
        return "events.event_types.music";
      }
      if (props.event.type === "projection") {
        return "events.event_types.movie";
      }
      if (props.event.type === "conference") {
        return "events.event_types.talk";
      }

      return "events.event_types.all";
    });

    const { currentLang } = useRouteWithLang();

    return {
      eventTypeLabel,
      currentLang,
    };
  },
});
